:global(section.modal) .wrapper :global(.modal-body) {
    padding: 0;
}

.container {
    display: flex;
}

.textContainer {
    width: 50%;
    padding: 16px 16px 32px;
    background-color: rgba(var(--color-primary) / 10%);
}

.imgContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    background-color: rgba(var(--color-primary) / 40%);
}

.rowContainer {
    margin-bottom: 21px;
}

.row {
    margin-bottom: 8px;
}

:global(article.card-block article.card-action).card {
    padding: 12px;
    border: 1px solid rgba(var(--color-grey) / 40%);
}
