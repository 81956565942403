.container {
    position: absolute;
    top: 50%;
    left: calc(50% + 100px);
    display: flex;
    column-gap: 16px;
    justify-content: center;
    width: auto;
    transform: translate(-50%, -50%);
}

.content {
    max-width: 315px;
}

@media only screen and (max-width: 480px) {
    .container {
        left: 50%;
        width: 100%;
        padding: 0 16px;
    }
}
