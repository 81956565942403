.container {
    display: flex;
    column-gap: 8px;
    align-items: flex-start;
    justify-content: center;
    padding: 12px;
    background-color: transparent;
    border: 1px solid rgba(var(--color-grey) / 40%);
    border-radius: 4px;
}

.container p {
    color: rgba(var(--color-grey));
}
