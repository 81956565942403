/* override sidebar */
aside.appnav-container {
    background-color: rgba(var(--color-primary)/15%);
}

nav.appnav .icon {
    width: 16px;
    min-width: 16px;
    max-width: 16px;
    height: 16px;
    margin-right: 8px;
}

nav.appnav li .submenu .menu-item {
    padding: 4px 8px 4px 32px;
}

@media only screen and (max-width: 480px) {
    aside.appnav-container {
        background: linear-gradient(rgba(var(--color-primary) / 10%), rgba(var(--color-primary) / 10%));
        background-color: white;
    }
}

/* end override sidebar */

/* override toasts */
section.toasts {
    bottom: 86px;
    filter: saturate(3.5);
}

/* end override toasts */

.bold-text {
    font-weight: 700;
}

/* override select temporary */
.selectWrapper {
    max-width: max-content;
}